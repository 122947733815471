import {
  PERMISSIONS_REQUEST,
  PERMISSIONS_REQUEST_FAIL,
  PERMISSIONS_REQUEST_SUCCESS,
} from '../Constants/permissionsConstant';

function getPermissionsReducer(state = { data: null }, action) {
  switch (action.type) {
    case PERMISSIONS_REQUEST:
      return { loading: true, data: null };
    case PERMISSIONS_REQUEST_SUCCESS:
      return { loading: false, data: action.payload.permissions };
    case PERMISSIONS_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export { getPermissionsReducer };
